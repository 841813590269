.monitor {
    background: #000; 
    max-width: 800px;
	position: relative;
	border-top: 3px solid #888; 
	margin: 5%;
	padding: 8px 8px 20px 8px; 
	border-radius: 10px; 
	border-bottom-left-radius: 50% 2%; 
	border-bottom-right-radius: 50% 2%; 
	transition: margin-right 1s;
}

.monitor:after {
	content: '';
	display: block;
	position: absolute;
	bottom: 3%;
	left: 36%;
	height: .5%; 
	width: 28%;
	background: #ddd; 
	border-radius: 50%; 
	box-shadow: 0 0 3px 0 white; 
}

.monitor img {
    width: 100%;
}