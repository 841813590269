.skill-icons-container {
    width: 100%;
}

.skill-icons-container h1 {
    width: 100%;
    color: #61F7D4;
    font-size: 35px;
    padding-bottom: 150px;
}

.skill-icons {
    max-height: 100vh;
    width: 100%!important;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.skill-icons-container div {
    width: 33%;
}

.skill-icons-container div img {
    width: 125px;
}

@media only screen and (max-width: 600px) {
    /* .skill-icons-container {
        flex-direction: column;
    } */

    .skill-icons-container div {
        width: 100%;
        padding-bottom: 35px;
    }

    .skill-icons {
        max-height: unset;
    }

    .skill-icons-container > div {
        padding-bottom: 55px;
    }

    .skill-icons-container div img {
        width: 100px;
    }
  }
  