.contact-form-container {
    height: 400px;
    margin: 0 30px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
}

.contact-form-container textarea, .contact-form-container input {
    background-color: transparent;
    border: 1px solid #61F7D4;
    padding: 15px;
    height: 100px;
    border-radius: 10px;
    color: #61F7D4;
    margin-bottom: 15px;
    font-size: 16px;
    font-family: monospace !important;
}

textarea {
    margin-top: 7px;
    min-height: 125px;
}