.project-description {
    width: 500px;
    margin-right: 100px;
    padding-left: 25px;
}

.project-description p {
    font-size: 22px;
}


@media only screen and (max-width: 600px) {
    .project-description {
        width: 100%;
        padding-left: 0;
        margin-right: 0;
    }
}