.download-resume-container {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin-top: 35vh;
}

.download-resume-container p {
    text-align: center;
}

a {
    text-align: center;
}