.accent-color {
  color: #64FFDA;
}

input:focus, textarea:focus {
  outline: none;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #282c34;
}

.App {
  text-align: center;
  max-width: 1200px;
}

.App p {
  text-align: left;
  color: white;
}

.App .page-container {
  min-height: 150vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App .page-container:last-child {
  min-height: 75vh!important;
}

.split-content-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  color: #61F7D4;
  border-radius: 15px;
  text-align: left;
  background-color: #282C34;
  padding: 45px;
}

.split-content-container div {
  display: flex;
  flex-direction: column;
}

p {
  margin-top: 0;
  font-size: 23px;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #61f7d4 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 6px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #61f7d4;
    border-radius: 10px;
    border: 0px solid #ffffff;
  }

  #__react-alert__ div div div {
    background-color: #282C34!important;
    border: 1px solid #61F7D4;
  }

  button {
    cursor: pointer;
    transition: 0.3s all;
    padding: 10px 25px;
  }

  button:hover {
    background-color: #61F7D4;
    color: #282C34;
  }

  /*Scroll Effect*/

  html.has-scroll-smooth {
    overflow: hidden; 
  }
  
  html.has-scroll-dragging {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
  }
  
  .has-scroll-smooth body {
    overflow: hidden; 
  }
  
  .has-scroll-smooth [data-scroll-container] {
    min-height: 100vh; 
  }
  
  [data-scroll-direction="horizontal"] [data-scroll-container] {
    height: 100vh;
    display: inline-block;
    white-space: nowrap; 
  }
  
  [data-scroll-direction="horizontal"] [data-scroll-section] {
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    height: 100%; 
  }
  
  .c-scrollbar {
    position: absolute;
    right: 0;
    top: 0;
    width: 11px;
    height: 100%;
    transform-origin: center right;
    transition: transform 0.3s, opacity 0.3s;
    opacity: 0; 
  }
  
    .c-scrollbar:hover {
      transform: scaleX(1.45); 
  }
  
    .c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
      opacity: 1; 
  }
  
  [data-scroll-direction="horizontal"] .c-scrollbar {
      width: 100%;
      height: 10px;
      top: auto;
      bottom: 0;
      transform: scaleY(1); 
  }
  
  [data-scroll-direction="horizontal"] .c-scrollbar:hover {
        transform: scaleY(1.3); 
  }
  
  .c-scrollbar_thumb {
    position: absolute;
    top: 0;
    right: 0;
    background-color: black;
    opacity: 0.5;
    width: 7px;
    border-radius: 10px;
    margin: 2px;
    cursor: -webkit-grab;
    cursor: grab; 
  }
    .has-scroll-dragging .c-scrollbar_thumb {
      cursor: -webkit-grabbing;
      cursor: grabbing; 
  }
    [data-scroll-direction="horizontal"] .c-scrollbar_thumb {
      right: auto;
      bottom: 0; 
  }

  @media only screen and (max-width: 600px) {

    body, html {
      max-width: 100vw;
      overflow-x: hidden;
    }

    .split-content-container, .App, .page-container {
      flex-direction: column;
      max-width: 100vw;
      max-height: unset;
      padding: 0;
    }
    .App .page-container {
      min-height: 100vh;
      padding: 0 20px;
      padding-bottom: 100px;
    }

    .App p {
      font-size: 21px!important;
      line-height: 31px;
    }
  }

  @media screen and (max-width: 600px) and (max-height: 700px) {
    .App .page-container:last-child {
      padding-bottom: 200px;
    }
  }