.about-container {
    background-color: #282C34;
    color: #61F7D4;
    padding: 60px 120px;
    border-radius: 7px;
    width: 90%;
    max-width: 1200px;
    align-items: center;
}

.about-text {
    margin: 20px 120px;
    font-size: 22px;
    width: 100%;
    max-width: 1000px;
    line-height: 44px;
    color: #64ffda;
}

.profile-picture-container {
    height: 250px;
    min-width: 250px;
    border: 4px solid #64ffda;
    rotate: 225deg;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    transition: 0.3s all;
    cursor: pointer;
    box-sizing: border-box;
}

.profile-picture-container:hover {
    border-left: 8px;
    border-bottom: 8px;
    border-right: 4px;
    border-top: 4px;
}

.profile-picture-container:hover .profile-picture-container-inner{
    top: -47px;
    left: -47px;
    right: -47px;
    bottom: -47px;
}

.profile-picture-container-inner {
    rotate: -225deg;
    position: absolute;
    top: -51px;
    left: -51px;
    right: -51px;
    bottom: -51px;
    background: url(../../img/profileImg.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center -20px;
}

@media only screen and (max-width: 600px) {
    .about-text {
        padding-bottom: 100px;
        margin-right: 0;
        margin-left: 0;
    }

    .profile-picture-container {
        height: 200px;
        min-width: 200px;
    }

}