.socialMediaLinks {
    position: fixed;
    list-style: none;
    color: #64FFDA;
    border: 1px solid #64FFDA;
    background-color: #282C34;
    left: -190px;
    top: calc(50vh - 60px);
    width: 450px;
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    padding: 0;
    transform: rotate(90deg);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.socialMediaLinks ul  {
    width: 100%;
}

.socialMediaLinks a {
    width: 33.3%;
}

.socialMediaLinks a li {
    width: 100%;
}

a {
    color: #64FFDA;
    text-decoration: none;
    transition: 0.3s all;
}

.socialMediaLinks a:first-child {
    border-top-left-radius: 15px;
    border-left: 1px solid #64FFDA;
}


.socialMediaLinks a:last-child {
    border-top-right-radius: 15px;
    border-right: 1px solid #64FFDA;
}

.socialMediaLinks a:hover, .socialMediaLinks a:focus {
    background-color: #64FFDA;
    color: #282C34;
}

.socialMediaLinks li {
    padding: 30px 0;
    width: 213.6px;
}

@media screen and (max-width: 600px) {
    .socialMediaLinks {
        transform: rotate(0);
        width: 100%;
        left:0;
        right: 0;
        bottom: 0;
        top: unset;
        margin: 0;
    }

    .socialMediaLinks li {
        padding: 20px 0;
    }
  }